<template>
    <div class="login-form">
        <AppRadioButton v-for="radio, index in checkOptions.radio" :key="index" :options="radio" @action="loginData.getPerson($event)" />          
        <BaseInput outlined rounded label="Ваш номер телефона" id="phone" width="320" margin="0 0 50px 0" validation="phone" required @sendData="loginData.getPhone($event)" />
        <BaseInput outlined rounded label="Пароль" id="password" width="320" margin="0 0 50px 0" validation="password" inputType="password" required @sendData="loginData.getPassword($event)" />
        <AppCheckInput :options="checkOptions" @checked="rememberMe($event)" />
        <div class="forgot-password" @click="dropPassword" tabindex="0">Забыли пароль?</div>
        <div class="registration-btn">
            <AppRegularBtn dark text="вход" :disabled="!isLoginDataValid" @action="loginData.sendLoginData" />
        </div>
    </div>
</template>

<script>
import AppRadioButton from "@/components/inputs/app-radio-input.vue";
import AppRegularBtn from "@/components/buttons/app-regular-btn.vue";
import AppCheckInput from "@/components/inputs/app-check-input.vue";
import { computed, reactive } from 'vue';
import { useRouter } from "vue-router";
    export default {
        components: {
            AppRegularBtn,
            AppCheckInput,
            AppRadioButton
        },
        setup(props, { emit }){
            const router = useRouter();

            const checkOptions = reactive({
                label: "Запомнить меня",
                id: "remember",
                name: "remember",
                radio: [
                    {
                        id: "massagist",
                        label: "Я массажист(-ка)",
                        name: "person",
                        checked: true
                    },
                    {
                        id: "saloon",
                        label: "Я массажный салон",
                        name: "person"
                    },
                    // {
                    //     id: "client",
                    //     label: "Я клиент",
                    //     name: "person"
                    // }
                ],
            });

            const loginData = reactive({
                phone: "",
                password: "",
                person: "massagist",
                remember: false,
                getPhone: (data) => loginData.phone = data,
                getPassword: (data) => loginData.password = data,
                getPerson: (data) => loginData.person = data, 
                sendLoginData: () => {
                    const user = {
                        phone: loginData.phone,
                        password: loginData.password,
                        person: loginData.person,
                        remember: loginData.remember
                    }
                    emit('sendData', user);
                }
            });

            const rememberMe = (data) => {
                loginData.remember = data.checked;
                console.log(loginData.remember)
            }

            const dropPassword = () => {
                router.push('/drop-password');
            }

            const isLoginDataValid = computed(() => {
                if(loginData.phone.length > 0 && loginData.password.length){
                    return true;
                } else {
                    return false
                }
            })

            return {
                checkOptions,
                loginData,
                isLoginDataValid,
                rememberMe,
                dropPassword
            }
        }
    }
</script>

<style lang="scss" scoped>
    .login-form{
        width: 80%;
        max-width: 320px;
        .registration-btn{
            width: 100%;
            text-align: center;
            padding: 2em 0;
        }
        .forgot-password{
            text-align: right;
            color: $accent-dark;
            font-size: 0.9em;
            font-weight: 700;
            cursor: pointer;
            text-decoration: none;
        }
}
</style>