<template>
    <AppPublicLayout>
        <template v-slot:header>
            <AppHeader />
        </template>
        <template v-slot:main>
            <div class="main-content">
                <div class="app-title">Массаж в <span>твоем</span> городе</div>
                <div class="page-title">Вход</div>
                <AppLoginForm @sendData="login($event)" />
                <AppPopupWarning :text="warning.text" v-if="warning.isActive" />
            </div>
        </template>
        <template v-slot:footer>
            <AppFooter />
            <AppNavigationMobile />
        </template>
    </AppPublicLayout>
</template>

<script>
import AppPublicLayout from "@/layouts/user/public/app-public-layout.vue";
import AppHeader from "@/sections/app-header.vue";
import AppFooter from "@/sections/app-footer.vue";
import AppNavigationMobile from "@/sections/app-navigation-mobile.vue";
import AppBasicInput from "@/components/inputs/app-basic-input.vue";
import AppLoginForm from "@/components/forms/app-login-form.vue";
import AppPopupWarning from "@/components/popups/app-popup-warning.vue";
import loginApi from "@/api/login.js";
import { useRouter } from "vue-router";
import { reactive } from 'vue';
import { useStore } from "vuex";
    export default {
        components: {
            AppPublicLayout,
            AppHeader,
            AppFooter,
            AppNavigationMobile,
            AppBasicInput,
            AppLoginForm,
            AppPopupWarning
        },
        setup(){
            const router = useRouter();
            const store = useStore();

            const warning = reactive({
                text: "",
                isActive: false
            })
            const formOptions = {               
                phone: {
                    label: 'Ваш номер телефона',
                    inputType: 'number',
                    id: 'phone'
                },
                password: {
                    label: 'Придумайте пароль',
                    inputType: 'password',
                    id: 'password'
                },                
            };

            const login = async (data) => {
                const user = await loginApi.login({
                    phone: data.phone,
                    password: data.password,
                    person: data.person,
                    remember: data.remember
                }).catch(err => console.log(err))
                
                if(user.data === "Некорректный пароль"){
                    warning.isActive = true;
                    warning.text = "Вы ввели неправильный пароль. Если вы забыли пароль, воспользуйтесь сервисом восстановления паролей";
                    setTimeout(() => {
                        warning.isActive = false;
                        warning.text = "";
                    }, 3000)
                } else if (user.data === 'Ваш аккаунт заблокирован'){
                    warning.isActive = true;
                    warning.text = "Ваш аккаунт заблокирован. Обратитесь к администратору сайта";
                    setTimeout(() => {
                        warning.isActive = false;
                        warning.text = "";
                    }, 3000)
                } else if (user.data === "Такого аккаунта не существует"){
                    warning.isActive = true;
                    warning.text = "Такого аккаунта не существует. Возможно, вы ввели некорректные данные";
                    setTimeout(() => {
                        warning.isActive = false;
                        warning.text = "";
                    }, 3000)
                } else {
                    if(user.data.status === "massagist"){
                        if(data.remember){
                            localStorage.setItem('user', JSON.stringify(user.data));
                            let mainPhoto;
                            if(user.data.photos.length > 0){
                                mainPhoto = user.data.photos.filter(photo => {
                                    if(photo.main_photo === 1){
                                        return photo
                                    }})
                                mainPhoto = mainPhoto[0]
                            } else {
                                mainPhoto = { link: 'user.png' }
                            }                        
                            store.dispatch('setUser', {
                                token: user.data.token,
                                status: user.data.status,
                                user: user.data.user,
                                finance: user.data.finance,
                                mainPhoto: mainPhoto.link,
                            });
                          
                            
                            router.push(`/massagist-personal-page/${store.state.user.user.id}/massagist-anket`);
                        } else {
                            let mainPhoto = { link: 'user.png' }
                            
                            store.dispatch('setUser', {
                                token: user.data.token,
                                status: user.data.status,
                                user: user.data.user,
                                finance: user.data.finance,
                                mainPhoto: mainPhoto.link
                            });
                            router.push(`/massagist-personal-page/${store.state.user.user.id}/massagist-anket`);
                        }
                    }
                    if(user.data.status === 'saloon'){
                        console.log(user)
                        if(data.remember){
                            localStorage.setItem('user', JSON.stringify(user.data));
                            let mainPhoto;
                            if(user.data.photos.length > 0){
                                mainPhoto = user.data.photos.filter(photo => {
                                    if(photo.main_photo === 1){
                                        return photo
                                    }})
                                mainPhoto = mainPhoto[0]
                            } else {
                                mainPhoto = { link: 'user.png' }
                            }                        
                            store.dispatch('setUser', {
                                token: user.data.token,
                                status: user.data.status,
                                user: user.data.user,
                                finance: user.data.finance,
                                mainPhoto: mainPhoto.link
                            });
                          
                            
                            router.push(`/massagist-personal-page/${store.state.user.user.id}/massagist-anket`);
                        } else {
                            let mainPhoto = { link: 'user.png' }
                            
                            store.dispatch('setUser', {
                                token: user.data.token,
                                status: user.data.status,
                                user: user.data.user,
                                finance: user.data.finance,
                                mainPhoto: mainPhoto.link
                            });
                            router.push(`/massagist-personal-page/${store.state.user.user.id}/massagist-anket`);
                        }
                    }
                    if(user.data.status === 'client'){
                        if(data.remember){
                            localStorage.setItem('user', JSON.stringify(user.data));
                            store.dispatch('setUser', {
                                token: user.data.token,
                                status: user.data.status,
                                id: user.data.user.id
                            });
                            
                            router.push(`/`);
                        } else {
                            store.dispatch('setUser', {
                                token: user.data.token,
                                status: user.data.status,
                                id: user.data.user.id
                            });
                            router.push(`/`);
                        }
                    }
                }
            }

       
            return {
                formOptions,
                login,
                warning
            }
        }
    }
</script>

<style lang="scss" scoped>
    .main-content{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .app-title{
            font-size: 1.5em;
            font-weight: 700;
            color: $black;
            span{
                color: $accent-dark;
            }
        }
        .page-title{
            font-size: 2.2em;
            color: $black;
            font-weight: 700;
            text-transform: uppercase;
            margin: 1em 0;
        }

    }

    @media screen and(min-width: 768px){
        .main-content{
            width: 60%;
            margin: 0 auto;
        }
    }
    @media screen and(min-width: 1280px){
        .main-content{
            width: 30%;
            margin: 0 auto;
        }
    }
</style>